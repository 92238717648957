import React from 'react'
// import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { navigate } from 'gatsby'
import Gallery from '../components/gallerySlider'
import Image from 'gatsby-image'
import Link from 'gatsby-link'

import { styles } from '../styles/styles.jsx'
import Layout from '../components/layout'

const Portfolio = ({ data }) => (
  <Layout>
    <BackgroundImage className="" fluid={data.caseStudy.image.fluid}>
      <div className={styles.caseStudyBgOverlay} />
      <div className={styles.container}>
        <div className={styles.caseStudyContainer}>
          <h2
            className={styles.caseStudyTag}
            onClick={() => navigate(`/portfolio`)}
            style={{ cursor: 'pointer' }}
          >
            ← Portfolio
          </h2>
          <h1 className={styles.caseStudyTitle}>
            {data.caseStudy.title}
          </h1>
          {/* <p className={styles.caseStudyDescription}>
            {data.caseStudy.description}
          </p> */}
        </div>
      </div>
    </BackgroundImage>
    <div className={styles.spacer}></div>
    <div className={styles.contentOuterContainer}>
      <div
        className={styles.container}
        style={{ top: '-3rem', display: 'flex' }}
      >
        <div className={styles.contentContainer} style={{ overflow: 'auto' }}>
          {data.caseStudy.content.map((block, index) => (
            <div className={styles.contentInner} key={block.id}>
              {block.model.apiKey === 'text' && (
                <div dangerouslySetInnerHTML={{ __html: block.html }} />
              )}
              {block.model.apiKey === 'image_carousel' && (
                <Gallery key={block.id} images={block.gallery} thumbnails={data.thumbnails.content[0].gallery} />
              )}
              {console.log(data)}
            </div>
          ))}
          {/* <div className={styles.contentAuthor} style={{ maxHeight: '100px' }}>
            <Image
              className={styles.contentAuthorPortrait}
              fixed={data.caseStudy.author.portrait.fixed}
            />
            <div
              className={
                styles.contentAuthorAttribution + ' ' + styles.contentAuthorBg
              }
            >
              <h2 className={styles.contentAuthorName}>
                {data.caseStudy.author.name}
              </h2>
              <p className={styles.contentAuthorTitle}>
                {data.caseStudy.author.title}
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <div className={styles.container}>
        <h2 className={styles.largeTitle}>More Case Studies</h2>
        <div className={styles.workContainer}>
          {data.allDatoCmsCaseStudy.edges.map(({ node: item }) => (
            <div
              className={styles.workItemContainer}
              key={item.id}
              style={{ boxSizing: 'border-box' }}
            >
              <div className={styles.workItem}>
                <BackgroundImage
                  fluid={item.image.fluid}
                  className={styles.workImage + ' ' + styles.workImageBg}
                />
                <div className={styles.workContent}>
                  <p className={styles.workTag}>Case Study</p>
                  <h2 className={styles.workItemTitle}>{item.title}</h2>
                  <p className={styles.workDescription}>{item.description}</p>
                  <Link
                    className={styles.workLink}
                    to={'/portfolio/' + item.slug}
                  >
                    View More
                </Link>
                </div>
              </div>
            </div>
          ))} 
        </div> 
      </div> 
    </div>
  </Layout>
)

export default Portfolio

export const caseStudyQuery = graphql`
  query caseStudyQuery($slug: String!) {
    caseStudy: datoCmsCaseStudy(slug: { eq: $slug }) {
      slug
      title
      description
      publishDate
      image {
        fluid(maxWidth: 500) {
          ...GatsbyDatoCmsFluid
        }
      }
      author {
        title
        name
        portrait {
          fixed(width: 100) {
            ...GatsbyDatoCmsFixed
          }
        }
      }
      content {
        ... on DatoCmsText {
          id
          html
          model {
            apiKey
          }
        }
          ... on DatoCmsImageCarousel {
            id
            model {
              apiKey
            }
            gallery {
              fluid(maxHeight: 500) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
      
      }
    }
    thumbnails: datoCmsCaseStudy(slug: { eq: $slug }) {
      slug
      content {
          ... on DatoCmsImageCarousel {
            id
            model {
              apiKey
            }
            gallery {
              fixed(height: 100) {
                ...GatsbyDatoCmsFixed
              }
            }
          }
      
      }
    }
    allDatoCmsCaseStudy(
      sort: { fields: [publishDate], order: DESC }
      limit: 2
    ) {
      edges {
        node {
          slug
          title
          description
          publishDate
          image {
            fluid(maxWidth: 500) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`
