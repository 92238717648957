import React, { Component } from 'react'
import Slider from 'react-slick'
import '../styles/react-slick-css.jsx'
import tw from '../../tailwind.js'
import { css } from 'emotion'
import { injectGlobal } from 'emotion'
import Image from 'gatsby-image'
import 'typeface-major-mono-display'

injectGlobal`
.gallerySlider {
  .slick-dots {
    bottom: -15px;
    z-index: 3;
  }
  .slick-dots li button::before {
    font-size: 18px;
  }
  .slick-dots li button::before {
    color: #c6d27e;
    content: '○';
  }
  .slick-dots li.slick-active button::before {
    color: white;
    content: '●';

  }
  .slick-arrow {
    height: 80px;
    width: 80px;
    z-index: 2;
  }
  .slick-prev {
    left: 0px;
    background-color: rgba(255,255,255,0.5);
  }
  .slick-next {
    right: 0px;
    background-color: rgba(255,255,255,0.5);
  }
  .slick-prev::before {
    content: "<";
  }
  .slick-next::before {
    content: ">";
  }
  .slick-prev::before, .slick-next::before {
    border-radius: 50%;
    color: #c6d27e;
    font-size: 80px;
    font-family: Major Mono Display !important;
    width: 80px;
    display: block;
  }
}
`

const styles = {
  quote: css(tw`text-white text-center`),
  quoteTitle: css(tw`uppercase text-5xl mb-0`),
  quoteAttribution: css(tw` font-normal text-2xl`),
}

class gallerySlider extends Component {
  constructor(props) {
      super(props);
      this.galleryRef = React.createRef();
      this.state = {
        gallery: null,
        thumbnails: null
      };
  } 



  componentDidMount() {
    this.setState({
      gallery: this.gallery,
      thumbnails: this.thumbnails
    });
  }
  render() {
    var settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 7000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      centerMode: false,
      centerPadding: '30px',
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    }
    var thumbnailSettings = {
      dots: false,
      infinite: true,
      autoplay: false,
      speed: 400,
      slidesToShow: 6,
      slidesToScroll: 1,
      arrows: true,
      centerMode: true,
      centerPadding: '30px',
      swipeToSlide: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    }
    return (
      <div className="gallerySlider">
        <Slider {...settings} asNavFor={this.state.thumbnails} ref={slider => (this.gallery = slider)}>
          {this.props.images.map((image, index) => (
            <div key={`gallery-${index}`} className={styles.image}>
              <Image fluid={image.fluid} />
            </div>
          ))}
        </Slider>
        {/* Thumbnails aren't working right at the moment. */}
        {/* <Slider {...thumbnailSettings} asNavFor={this.state.gallery} ref={slider => (this.thumbnails = slider)}>
          {this.props.thumbnails.map((image, index) => (
            <div key={`thumbnail-${index}`} className={styles.image}>
              <Image fixed={image.fixed} style={{ width:'100%'}}/>
            </div>
          ))}
        </Slider> */}
      </div>
    )
  }
}

export default gallerySlider
